const sponsorsData = [
    {
        id: 1,
        name: 'overstims',
        img: 'logo-overstims-nutrition-sportive-tonton-outdoor.jpg',
        link: 'https://www.overstims.com/',
    },
    {
        id: 2,
        name: 'Damrys',
        img: 'damyrs.jpg',
        link: 'https://www.damrys.fr/',
    },
    {
        id: 3,
        name: 'Delanchy',
        img: 'delanchyLogo.jpg',
        link: 'https://www.delanchy.com/',
    },
    {
        id: 4,
        name: 'Demy',
        img: 'demy.jpg',
        link: 'https://www.sademy.com/',
    },
    {
        id: 5,
        name: 'Daniel-mouton',
        img: 'daniel-mouton.jpg',
        link: 'https://www.daniel-mouton.com/',
    },
    {
        id: 6,
        name: 'Fenêtre sur goût',
        img: 'fenêtre-sur-gout.png',
        link: 'https://www.boucheriepainchaud.com/',
    },
    {
        id: 7,
        name: 'Groupama',
        img: 'groupamaLogo.jpg',
        link: 'https://www.groupama.fr/',
    },
    {
        id: 8,
        name: 'Intermarché',
        img: 'intermarché.jpg',
        link: 'https://www.intermarche.com/',
    },
    {
        id: 9,
        name: 'Lafarge',
        img: 'lafarge.jpg',
        link: 'https://www.lafarge.fr/',
    },
    {
        id: 10,
        name: 'le Pape Voyages',
        img: 'rgo-mobilité-proposition.jpg',
        link: 'https://www.le-pape-autocars.com/',
    },
    {
        id: 11,
        name: 'Groupe Pigeon',
        img: 'groupe-pigeon.jpg',
        link: 'https://www.groupe-pigeon.com/',
    },
    {
        id: 12,
        name: 'Cimalp',
        img: 'cimalp.jpg',
        link: 'https://www.cimalp.fr',
    },
    {
        id: 13,
        name: 'Planchais',
        img: 'planchais.jpg',
        link: 'https://www.planchais.fr/',
    },
    {
        id: 14,
        name: "Chai d'Anthon",
        img: 'chai_1.png',
        link: 'https://www.chai-danthon.fr/',
    },
    {
        id: 15,
        name: 'Intersport',
        img: 'intersport.jpg',
        link: 'https://www.intersport.fr/Ille-et-Vilaine-35/VITRE-35500/INTERSPORT-VITRE/00893_000/',
    },
    {
        id: 16,
        name: 'Roady Vitré',
        img: 'roady-vitré.jpg',
        link: 'https://www.roady.fr/vitre.html',
    },
    {
        id: 17,
        name: 'Chocolat le Derf',
        img: 'chocolat-le-derf.jpg',
        link: 'https://www.brunolederf.fr/',
    },
    {
        id: 18,
        name: 'St Michel',
        img: 'st-michel.jpg',
        link: 'https://www.stmichel.fr/',
    },
    {
        id: 19,
        name: 'Armor Lux',
        img: 'armorlux.jpg',
        link: 'https://www.armorlux.fr',
    },
    {
        id: 20,
        name: "Grillon d'or",
        img: "grillon-d'or.jpg",
        link: 'https://www.grillondor.bio/',
    },
    {
        id: 21,
        name: 'Maison Hermine',
        img: 'maison-hermine.jpg',
        link: 'https://www.maisonhermine.fr/',
    },
    {
        id: 22,
        name: 'We recruite',
        img: 'we-recruite.jpg',
        link: 'https://werecruit.com/',
    },
    {
        id: 23,
        name: 'Mytilimer',
        img: 'logotype_mytilimer_RVB_fondbleu.png',
        link: 'https://www.lacancalaise.fr/',
    },
    {
        id: 24,
        name: 'interaction',
        img: 'interaction.png',
        link: 'https://werecruit.com/',
    },
];

export default sponsorsData;
